import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import { Sidebar2023 } from 'views/SchoolProgram2023/components';
import Sidebar2024 from '../SchoolProgram2023/components/Sidebar2024';
import Archive2022 from '../SchoolProgram2023/components/Archive2022';


const Valedictoryceremony9to122024 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });


    const [config, setConfig] = useState({ base_image_url: '' });
    useEffect(() => {
        fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
            .then(response => response.json())
            .then(data => setConfig(data))
            .catch(error => console.error('Error fetching config:', error));
    }, []);

    const p1 = `${config.base_image_url}/home/events-activities/school-programs/Valedictoryceremony/1.webp`;
    const p2 = `${config.base_image_url}/home/events-activities/school-programs/Valedictoryceremony/2.webp`;
    const p3 = `${config.base_image_url}/home/events-activities/school-programs/Valedictoryceremony/3.webp`;
    const p4 = `${config.base_image_url}/home/events-activities/school-programs/Valedictoryceremony/4.webp`;
    const p5 = `${config.base_image_url}/home/events-activities/school-programs/Valedictoryceremony/5.webp`;
    const p6 = `${config.base_image_url}/home/events-activities/school-programs/Valedictoryceremony/6.webp`;
    const p7 = `${config.base_image_url}/home/events-activities/school-programs/Valedictoryceremony/7.webp`;
    const p8 = `${config.base_image_url}/home/events-activities/school-programs/Valedictoryceremony/8.webp`;
    const p9 = `${config.base_image_url}/home/events-activities/school-programs/Valedictoryceremony/9.webp`;
    const p10 = `${config.base_image_url}/home/events-activities/school-programs/Valedictoryceremony/10.webp`;
    const p11 = `${config.base_image_url}/home/events-activities/school-programs/Valedictoryceremony/11.webp`;
    const p12 = `${config.base_image_url}/home/events-activities/school-programs/Valedictoryceremony/12.webp`;
    const p13 = `${config.base_image_url}/home/events-activities/school-programs/Valedictoryceremony/13.webp`;
    const p14 = `${config.base_image_url}/home/events-activities/school-programs/Valedictoryceremony/14.webp`;
    const p15 = `${config.base_image_url}/home/events-activities/school-programs/Valedictoryceremony/15.webp`;
    const p16 = `${config.base_image_url}/home/events-activities/school-programs/Valedictoryceremony/16.webp`;
    const p17 = `${config.base_image_url}/home/events-activities/school-programs/Valedictoryceremony/17.webp`;
    const p18 = `${config.base_image_url}/home/events-activities/school-programs/Valedictoryceremony/18.webp`;
    const p19 = `${config.base_image_url}/home/events-activities/school-programs/Valedictoryceremony/19.webp`;
    const p20 = `${config.base_image_url}/home/events-activities/school-programs/Valedictoryceremony/20.webp`;
    const p21 = `${config.base_image_url}/home/events-activities/school-programs/Valedictoryceremony/21.webp`;
    const p22 = `${config.base_image_url}/home/events-activities/school-programs/Valedictoryceremony/22.webp`;

    const photos = [
        {
            src: p1,
            source: p1,
            rows: 1,
            cols: 1,
        },
        {
            src: p2,
            source: p2,
            rows: 1,
            cols: 1,
        },
        {
            src: p3,
            source: p3,
            rows: 1,
            cols: 1,
        },
        {
            src: p4,
            source: p4,
            rows: 1,
            cols: 1,
        },
        {
            src: p5,
            source: p5,
            rows: 1.5,
            cols: 1,
        },

        {
            src: p6,
            source: p6,
            rows: 1,
            cols: 1,
        },
        {
            src: p7,
            source: p7,
            rows: 1.5,
            cols: 1,
        },

        {
            src: p8,
            source: p8,
            rows: 1,
            cols: 1,
        },
        {
            src: p9,
            source: p9,
            rows: 1.5,
            cols: 1,
        },
        {
            src: p10,
            source: p10,
            rows: 1,
            cols: 1,
        },
        {
            src: p11,
            source: p11,
            rows: 1,
            cols: 1,
        },
        {
            src: p12,
            source: p12,
            rows: 1,
            cols: 1,
        },
        {
            src: p13,
            source: p13,
            rows: 1,
            cols: 1,
        },

        {
            src: p14,
            source: p14,
            rows: 1,
            cols: 1,
        },
        {
            src: p15,
            source: p15,
            rows: 1,
            cols: 1,
        },
        {
            src: p16,
            source: p16,
            rows: 1,
            cols: 1,
        },
        {
            src: p17,
            source: p17,
            rows: 2,
            cols: 1.4,
        },
        {
            src: p18,
            source: p18,
            rows: 2,
            cols: 1.4,
        },
        {
            src: p19,
            source: p19,
            rows: 2,
            cols: 1.4,
        },
        {
            src: p20,
            source: p20,
            rows: 2,
            cols: 1.4,
        },

        {
            src: p21,
            source: p21,
            rows: 2,
            cols: 1.4,
        },
        {
            src: p22,
            source: p22,
            rows: 2,
            cols: 1.4,
        },

    ];

    return (
        <Main>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box >
                                <Typography variant='h4' align='center'>
                                    Valedictory Ceremony
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                    Classes:- 9 to 12             Date: 16 January 2025
                                </Typography>
                                {/* <Typography
                                    variant={'h6'}
                                    color={'primary'}
                                    align={'center'}
                                    fontWeight={'normal'}
                                ><br />
                                    “A joy that is shared is the joy that is doubled” Valedictoryceremony, a festival of joy brings with itself the
                                    special message of hope, happiness and joy”<br />
                                </Typography> */}
                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                    National Public School, Yeshwanthpur hosted a memorable Valedictory Ceremony for the students
                                    of classes 9-12 on 16 January 2025. The ceremony commenced with the march past by the cabinet
                                    members, exuding grace and discipline. The cabinet members ceremonially handed over the charge
                                    to their counterparts, symbolising the transfer of responsibility and leadership. The cabinet
                                    members of class 12 were presented Certificate of Commendation by the Principal.
                                    <br></br>
                                    Thereafter, students were felicitated with trophies and certificates for their outstanding academic
                                    performance during the session 2023-24. Award for the Best Outgoing Student was presented
                                    amidst applause and cheers. The Principal, Ms Sheeja Manoj, delivered an insightful speech. She
                                    praised the students of class 12 for the outstanding discipline and encouraged them for their future
                                    endeavours.
                                    <br></br>
                                    The valedictory event concluded with a heartfelt note of gratitude. The event left everyone inspired
                                    to strive for excellence and success.<br></br>

                                </Typography>

                            </Box>
                            <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                Success is the sum of small efforts, repeated day in and day out.
                                <br />

                            </Typography>


                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 200 : 100}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box marginBottom={4}>
                            <Sidebar2024 />
                        </Box>
                        <Box marginBottom={4}>
                            <Archive2022 />
                        </Box>

                    </Grid>
                </Grid>
            </Container>
        </Main>

    );
};

export default Valedictoryceremony9to122024;